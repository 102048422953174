<template>
  <div class="statistic">
    <van-tabs
      class="tabs mbox-tabs"
      type="card"
      v-model="tabid"
      @change="tabChange"
    >
      <van-tab
        v-for="tab in tabs"
        :key="tab.value"
        :title="tab.name"
        :name="tab.value"
      />
      <div class="mycard">
        <div class="content">
          <van-row gutter="10">
            <van-col span="5">
              {{ stat.open_box_count }}次
              <span class="muted">消费次数</span>
            </van-col>
            <van-col span="7">
              ￥{{ stat.open_box_cost }}
              <span class="muted">消费总额</span>
            </van-col>
            <van-col span="5">
              {{ stat.rebate_setting }}%
              <span class="muted">我的分成比例</span>
            </van-col>
            <van-col span="7">
              ￥{{ stat.total_rebate }}
              <span class="muted">返利金额</span>
            </van-col>
          </van-row>
        </div>
        <div class="end van-hairline--top">
          <div class="tac">
            <span class="muted">可提现金额</span>
            ￥{{ stat.cash }}
          </div>
          <!-- <van-button
            class="fr"
            text="查看提现记录"
            size="small"
            type="info"
            :to="{ name: 'Bills' }"
          /> -->
        </div>
      </div>
      <van-empty
        v-if="!stat.data || stat.data.length < 1"
        description="暂无记录"
      />
      <van-pull-refresh v-model="pullLoading" @refresh="refresh">
        <van-list v-model="loading" :finished="finished" @load="getList">
          <van-cell-group inset class="list">
            <van-cell v-for="(list, i) in stat.data" :key="i">
              <template #default>
                <div class="icon">
                  <van-image :src="list.user.icon">
                    <template v-slot:error>
                      <img :src="null | userIconFix" />
                    </template>
                  </van-image>
                </div>
                <van-row class="info">
                  <van-col span="12" class="van-ellipsis">
                    <span class="strong">
                      {{ list.user.username }}
                    </span>
                  </van-col>
                  <van-col span="12" class="tar">ID：{{ list.uid }}</van-col>
                  <van-col span="9">累积消费</van-col>
                  <van-col span="15" class="tar">￥{{ list.fee }}</van-col>
                  <van-col span="9">累积返利</van-col>
                  <van-col span="15" class="tar">￥{{ list.rebate }}</van-col>
                </van-row>
              </template>
            </van-cell>
          </van-cell-group>
        </van-list>
      </van-pull-refresh>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: "Statistic",
  data() {
    return {
      stat: {},
      page: 0,
      loading: false,
      finished: false,
      pullLoading: false,
      tabid: 0,
      tabs: [
        {
          name: "今天",
          value: 0,
        },
        {
          name: "近3天",
          value: 1,
        },
        {
          name: "近7天",
          value: 2,
        },
        {
          name: "近30天",
          value: 3,
        },
        {
          name: "全部",
          value: -1,
        },
      ],
    };
  },
  computed: {
    User() {
      return this.$store.state.User;
    },
  },
  created() {},
  methods: {
    refresh() {
      this.page = 0;
      this.getList();
    },
    tabChange(tabid) {
      this.page = 0;
      this.tabid = tabid;
      this.getList();
    },
    getList() {
      this.$ajax
        .post("/agent/agent/stat/", {
          uid: this.User.uid,
          t: this.tabid,
        })
        .then((res) => {
          this.stat = res;
          //未免要做分页
          this.loading = false;
          this.pullLoading = false;
          this.finished = true;
        });
    },
  },
};
</script>
